<template>
  <div class="fixed-page1">
    <div class="content">
      <Breadcrumb class="breadcrumb-1400" :list="breadList" />
      <div class="contentBox pl260">
        <leftMenu :id="listId" @chlickChildItem="clickCItem"></leftMenu>
        <div class="introContent">
          <div class="introTitle">
            <img src="../../../assets/img/renyiOverview/jiantou.png" />
            <span class="title">首都剧场</span>
          </div>
          <div style="padding: 0 2.6042vw">
            <div v-html="richText1"></div>
          </div>
          <div id="shoudu">
            <div class="shoudu2">首都剧场介绍</div>
            <div v-html="richText2" style="padding: 0 2.6042vw"></div>
          </div>
          <div id="beijingrenyi">
            <div class="shoudu2">北京人艺实验剧场</div>
            <div v-html="richText3" style="padding: 0 2.6042vw"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import leftMenu from "./LeftMenu";
import { getArticle } from "@/service/renyiOverview";
import { formatRichText } from "@/utils/formatRichText";
export default {
  components: {
    leftMenu,
  },
  data () {
    return {
      listId: 3,
      breadList: ["首都剧场"],
      form1: {
        level1: "SUMMARY",
        level2: "shoudu1",
      },
      form2: {
        level1: "SUMMARY",
        level2: "shoudu2",
      },
      form3: {
        level1: "SUMMARY",
        level2: "renyishiyan",
      },
      richText1: "",
      richText2: "",
      richText3: "",
    };
  },
  created () {
    this.getshouduContent();
    if (parseInt(this.$route.query.id)) {
      this.listId = parseInt(this.$route.query.id);
    }
  },
  updated () {
    if (this.$route.query.id) {
      if (this.$route.query.id == 4) {
        // document
        //   .getElementById("shoudu")
        //   .scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          this.clickCItem(4);
        }, 500)
      }
      if (this.$route.query.id == 5) {
        // this.$set(this.breadList, 0, "北京人艺实验剧场");
        // document
        //   .getElementById("beijingrenyi")
        //   .scrollIntoView({ behavior: "smooth" });
        setTimeout(() => {
          this.clickCItem(5);
        }, 500)
      }
    }
  },
  methods: {
    async getshouduContent () {
      const params = [this.form1, this.form2, this.form3];
      const promises = params.map((params) => {
        return getArticle(params);
      });
      Promise.all(promises).then((res) => {
        this.richText1 = this.addFontSizeToUlTags(
          formatRichText(res[0].data.body)
        );
        this.richText2 = this.addFontSizeToUlTags(
          formatRichText(res[1].data.body)
        );
        this.richText3 = this.addFontSizeToUlTags(
          formatRichText(res[2].data.body)
        );
      });
    },
    addFontSizeToUlTags (html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const ulTags = doc.querySelectorAll("span");

      ulTags.forEach(function (span) {
        span.style.fontSize = "0.9375vw";
      });

      return doc.documentElement.innerHTML;
    },
    clickCItem (id) {
      if (id == 4) {
        document
          .getElementById("shoudu")
          .scrollIntoView({ behavior: "smooth" });
      }
      if (id == 5) {
        // this.$set(this.breadList, 0, "北京人艺实验剧场");
        document
          .getElementById("beijingrenyi")
          .scrollIntoView({ behavior: "smooth" });
      }
      if (id === 7 || id === 8) {
        this.$router.push({
          path: "/renyiTheatre/theatreCenter",
          query: {
            id,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pl260 {
  padding-left: 13.5417vw;
}
.pl120 {
  padding-left: 3.125vw;
}
.fixed-page1 {
  background: #f8f8fa;
  width: 100%;
  text-align: left;
  background-image: url("~@/assets/img/performance/bg-4.png"),
    url("~@/assets/img/performance/bg-5.png");
  background-position: top left, bottom right;
  background-repeat: no-repeat;
  background-size: 60% auto, 10% auto;
}
.contentBox {
  display: flex;
  padding-bottom: 2.6042vw;
  box-sizing: border-box;
  .introContent {
    width: 56.25vw;
    background: #ffffff;
    margin-left: 1.0417vw;
    padding: 2.0833vw 0;
    box-sizing: border-box;
    .introTitle {
      display: flex;
      align-items: center;
      margin-left: 2.6042vw;
      margin-bottom: 1.875vw;
      img {
        width: 0.7292vw;
        height: 1.3542vw;
        margin-right: 0.625vw;
      }
      .title {
        font-size: 1.3542vw;
        font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
        font-weight: 800;
        color: #212122;
      }
    }
  }
}
#shoudu {
  .shoudu2 {
    font-size: 0.8333vw;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #212122;
    margin: 2.0833vw 0 0.7813vw 2.6042vw;
  }
}
#beijingrenyi {
  .shoudu2 {
    font-size: 0.8333vw;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 600;
    color: #212122;
    margin: 2.0833vw 0 0.7813vw 2.6042vw;
  }
}
</style>
