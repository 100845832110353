<template>
  <div class="fixed-page1">
    <div class="content">
      <Breadcrumb class="breadcrumb-1400" :list="breadList" />
      <div class="contentBox pl260">
        <leftMenu :id="listId" @chlickChildItem="clickCItem"></leftMenu>
        <div class="introContent">
          <div class="introTitle">
            <img src="../../../assets/img/renyiOverview/jiantou.png" />
            <span class="title">菊隐剧场</span>
          </div>
          <div style="padding: 0 2.6042vw">
            <div v-html="richText"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import leftMenu from "./LeftMenu";
import { getArticle } from "@/service/renyiOverview";
import { formatRichText } from "@/utils/formatRichText";
export default {
  components: {
    leftMenu,
  },
  data () {
    return {
      listId: 9,
      breadList: ["菊隐剧场"],
      form: {
        level1: "SUMMARY",
        level2: "JYJC",
      },
      richText: "",
    };
  },
  created () {
    this.getJuyinContent();
  },
  methods: {
    addFontSizeToUlTags (html) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const ulTags = doc.querySelectorAll("span");

      ulTags.forEach(function (span) {
        span.style.fontSize = "0.9375vw";
      });

      return doc.documentElement.innerHTML;
    },
    async getJuyinContent () {
      const params = { ...this.form };
      const res = await getArticle(params);
      this.richText = this.addFontSizeToUlTags(formatRichText(res.data.body));
    },
    clickCItem (id) {
      if (id === 4 || id === 5) {
        this.$router.push({
          path: "/renyiTheatre/capitalTheatre",
          query: {
            id,
          },
        });
      }
      if (id === 7 || id === 8) {
        this.$router.push({
          path: "/renyiTheatre/theatreCenter",
          query: {
            id,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pl260 {
  padding-left: 13.5417vw;
}
.pl120 {
  padding-left: 3.125vw;
}
.fixed-page1 {
  background: #f8f8fa;
  width: 100%;
  text-align: left;
  background-image: url("~@/assets/img/performance/bg-4.png"),
    url("~@/assets/img/performance/bg-5.png");
  background-position: top left, bottom right;
  background-repeat: no-repeat;
  background-size: 60% auto, 10% auto;
}
.contentBox {
  display: flex;
  padding-bottom: 2.6042vw;
  box-sizing: border-box;
  .introContent {
    width: 56.25vw;
    background: #ffffff;
    margin-left: 1.0417vw;
    padding: 2.0833vw 0;
    box-sizing: border-box;
    .introTitle {
      display: flex;
      align-items: center;
      margin-left: 2.6042vw;
      margin-bottom: 1.875vw;
      img {
        width: 0.7292vw;
        height: 1.3542vw;
        margin-right: 0.625vw;
      }
      .title {
        font-size: 1.3542vw;
        font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
        font-weight: 800;
        color: #212122;
      }
    }
  }
}
</style>
