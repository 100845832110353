<template>
  <div class="content">
    <div class="content-top">
      <div class="chin-title">人艺剧场</div>
      <div class="eng-title">People's Theatre</div>
    </div>
    <div class="content-bottom">
      <div class="list-box">
        <div
          @click="listClick(item.id, item.url)"
          v-for="item in list"
          :key="item.id"
          :class="listId == item.id ? 'list-active' : 'list'"
        >
          <div class="list-content">
            <div
              class="list-left"
              :class="
                item.id == 4 || item.id == 5 || item.id == 7 || item.id == 8
                  ? 'ml5'
                  : ''
              "
            >
              <div
                v-if="
                  item.id == 4 || item.id == 5 || item.id == 7 || item.id == 8
                "
                class="dot"
              ></div>
              {{ item.name }}
            </div>
            <div v-if="listId == item.id" class="list-right">——</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      list: [
        // {
        //   id: 1,
        //   name: "北京人艺演出中心",
        //   url: "/renyiTheatre/introduction",
        // },
        // {
        //   id: 2,
        //   name: "组织机构",
        //   url: "/renyiOverView/organization",
        // },
        {
          id: 3,
          name: "首都剧场",
          url: "/renyiTheatre/capitalTheatre",
        },
        {
          id: 4,
          name: "首都剧场",
          url: "",
        },
        {
          id: 5,
          name: "北京人艺实验剧场",
          url: "",
        },
        {
          id: 6,
          name: "北京国际戏剧中心",
          url: "/renyiTheatre/theatreCenter",
        },
        {
          id: 7,
          name: "曹禺剧场",
          url: "",
        },
        {
          id: 8,
          name: "人艺小剧场",
          url: "",
        },
        {
          id: 9,
          name: "菊隐剧场",
          url: "/renyiTheatre/juYinTheatre",
        },
        // {
        //   id: 11,
        //   name: "北京人艺发展基金会",
        //   url: "/renyiTheatre/foundation",
        // },
        // {
        //   id: 10,
        //   name: "北京人艺戏剧书店",
        //   url: "/renyiTheatre/createCenter",
        // },
      ],
      listId: this.id,
    };
  },
  methods: {
    listClick(id, url) {
      if (this.listId !== id) {
        this.listId = id;
        if (url) {
          this.$router.push(url);
        } else {
          this.$emit("chlickChildItem", id);
        }
      }
    },
  },
};
</script>
<style scoped lang="less">
.dot {
  width: 0.2083vw;
  height: 0.2083vw;
  border-radius: 50%;
  background-color: #f9680d;
  margin-right: 0.3125vw;
}
.list-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ml5 {
  margin-left: 0.2604vw;
}
.content {
  width: 15.625vw;
  height: 35.7292vw;
  position: relative;

  .content-top {
    width: 14.5833vw;
    height: 35.6771vw;
    // background-image: ;
    background: url("../../../assets/imgs/bg-list.png") no-repeat;
    background-size: 100%;

    .chin-title {
      position: absolute;
      top: 1.5625vw;
      left: 1.0417vw;
      width: 6.6667vw;
      height: 1.6667vw;
      font-size: 1.6667vw;
      font-family: SourceHanSerifSC-Bold, SourceHanSerifSC;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.6667vw;
    }

    .eng-title {
      position: absolute;
      top: 3.75vw;
      left: 1.0417vw;
      width: 8.2813vw;
      height: 0.7292vw;
      font-size: 0.7292vw;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.7292vw;
    }
  }

  .content-bottom {
    position: absolute;
    width: 14.5833vw;
    height: 30.2083vw;
    left: 1.0417vw;
    top: 5.5208vw;
    background: #ffffff;

    .list-box {
      width: 100%;
      height: 27.0833vw;
      margin-top: 1.5625vw;
      margin-bottom: 1.5625vw;
      font-size: 0.8333vw;

      .list {
        width: 100%;
        height: 2.6042vw;

        .list-content {
          margin-left: 1.5625vw;
          margin-right: 1.5625vw;
          width: 11.4583vw;
          height: 2.6042vw;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          line-height: 2.6042vw;
        }
      }
      .list-active {
        width: 100%;
        height: 2.6042vw;
        background: #21424b;
        color: #ffffff;
        font-size: 0.8333vw;

        .list-content {
          width: 11.4583vw;
          height: 2.6042vw;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          line-height: 2.6042vw;
          margin-left: 1.5625vw;
          margin-right: 1.5625vw;
        }
      }
    }
  }
}
</style>
