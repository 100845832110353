import service from "./index";

//获取文章详情
export const getArticle = (data) => {
  return service({
    url: "/open/portal/article/getByLevel",
    method: "post",
    data,
  });
};

//获取组织机构树
export const getOrgTreeList = (data = {}) => {
  return service({
    url: `/open/portal/org/list`,
    method: "post",
    data,
  });
};

//获取组织详情
export const getOrgDetail = (id) => {
  return service({
    url: `/open/portal/org/getById/${id}`,
    method: "get",
    id,
  });
};
